:root {
  --clr-blk: #0d0d0d;
  --clr-dark-blue: #002744;
  --clr-accent-blue: #18a0fb;
  --clr-semi-blue: #137dc4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body,
#root,
.App {
  min-height: 100vh;
  min-height: 100vh;
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
}

.map-container {
  width: 85vw;
  height: 100%;
}

/*! === SCROLLBAR === */
::-webkit-scrollbar {
  width: 11px;
  background: #24232b;
}

::-webkit-scrollbar-track {
  background: #24232b;
}

::-webkit-scrollbar-thumb {
  background: rgba(110, 118, 129, 0.4);
}

::-webkit-scrollbar-thumb:hover {
  background: #7a797e;
}
